import React from "react";
import "./App.css";
import domtoimage from "dom-to-image";
import "bootstrap/dist/css/bootstrap.min.css";
import Preview from "./components/Preview";

import "filepond/dist/filepond.min.css";
import Inputs from "./components/Inputs";
import Header from "./components/Header";



var sanitize = require("sanitize-filename");

class App extends React.Component {
  constructor() {
    super();

    let server = "//localhost:3001"
    if(window.location.hostname !== "localhost") {
      server =  "//" + window.location.hostname + "/api"
    } 

    this.state = {
      bgSize: "100",
      image: "default.jpg",
      text: "Kort stukje tekst",
      cat: "projectmanagement",
      canvasSize: "linkedin",
      server: server
    };
  }

  handleSlider = e => {
    this.setState({
      bgSize: e.target.value
    });
  };

  handleText = e => {
    this.setState({
      text: e.target.value
    })
  };

  handleUpload = (error, file) => {
    try {
      console.log("Setting image to", sanitize(file.file.name));
      this.setState({ image: sanitize(file.file.name) });
    } catch {
      this.setState({ image: "default.jpg" });
    }
  };

  setCanvas = e => {
    console.log(e.target.value);
    this.setState({
      canvasSize: e.target.value
    });
  };

  setCat = e => {
    console.log(e.target.value);
    this.setState({
      cat: e.target.value
    });
  };

  downloadImage = () => {
    const element = document.getElementById('preview')
    domtoimage.toPng(element).then(function(dataUrl) {
      var link = document.createElement("a");
      link.download = "SocialMediaImage.png";
      link.href = dataUrl;
      link.click();
    });
  };

  render() {
    return (
      <>
      <Header />
        <Preview
          bgSize={this.state.bgSize}
          image={this.state.image}
          text={this.state.text}
          cat={this.state.cat}
          canvasSize={this.state.canvasSize}
          server={this.state.server}
        />

        <Inputs
          bgSize={this.state.bgSize}
          text={this.state.text}
          setCanvas={this.setCanvas}
          setCat={this.setCat}
          handleSlider={this.handleSlider}
          handleUpload={this.handleUpload}
          handleText={this.handleText}
          downloadImage={this.downloadImage}
          server={this.state.server}
        />
      </>
    );
  }
}

export default App;
